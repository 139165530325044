import { useEffect, useCallback } from 'react'

const LOG_EVENT_NAME = 'sessionLogStarted'
const MESSAGE_EVENT = 'message'

export function useSessionLogEvent(handler: () => void) {
  const handleExternalEvent = useCallback(
    (event: Event) =>
      (event as MessageEvent).data === LOG_EVENT_NAME && handler(),
    [],
  )

  useEffect(() => {
    window.addEventListener(MESSAGE_EVENT, handleExternalEvent, false)

    return () => window.removeEventListener(MESSAGE_EVENT, handleExternalEvent)
  }, [handleExternalEvent])
}
